import Vue from 'vue'
import sys from '@/services/system';

function checkAccess(chapter, permissions) {
    const cfg = process.env.VUE_APP_CONFIGURATION;

    switch (chapter.route)
    {   
            case "/":
                return cfg === sys.Configurations.C4 && Array.from(permissions).includes('Documents');
            case "/projects":
                return cfg === sys.Configurations.C4 && permissions && Array.from(permissions).includes('DocumentsDrafts');
            case "/requests":
            case "/contracts":
                return cfg === sys.Configurations.C5 && permissions && Array.from(permissions).includes('CityStatementCenter');
            case "/inwork":
                return (cfg === sys.Configurations.C4 || cfg === sys.Configurations.C5) && permissions && Array.from(permissions).includes('MyDocuments');
            case "/handled":
                return cfg === sys.Configurations.C4 && permissions && Array.from(permissions).includes('MyDocuments');
            case "/chancellary":
                return cfg === sys.Configurations.C4 && permissions && Array.from(permissions).includes('Chancellery');
            case "/correspondence":
                return cfg === sys.Configurations.C4 && permissions && Array.from(permissions).includes('Documents');
            case "/events-management":
                return cfg === sys.Configurations.C4;
            case "/archive":
                return cfg === sys.Configurations.C4 && permissions && Array.from(permissions).includes('Documents');
            case "/dsp":
                return cfg === sys.Configurations.C4 && permissions && Array.from(permissions).includes('DocumentsDSP');
            case "/executioncontrol":
                return cfg === sys.Configurations.C4 && permissions && Array.from(permissions).includes('ExecutionControl');
            case "/tasks":
                return (cfg === sys.Configurations.C4 || cfg === sys.Configurations.C5) && Array.from(permissions).includes('Documents');
            case "/reports":
                return cfg === sys.Configurations.C4 && permissions && Array.from(permissions).includes('Chancellery');
            case "/settings":
                return cfg === sys.Configurations.C4 && permissions ? (permissions.includes('Administration') && permissions.includes('References')) || permissions.includes('Archivarius') : false;
            case "/npa":
                return cfg === sys.Configurations.C4 && permissions && Array.from(permissions).includes('NPA');
            case "/reportsgeneration":
                return cfg === sys.Configurations.C4;
    }
}

const header =
{
    namespaced: true,
    state: {
        tab: null,
        chapters: [
            { 
                name: "Главная",
                route: "/",
            },
            { 
                name: "Проекты",
                route: "/projects",
            },

            { 
                name: "В_работе",
                route: "/inwork",
            },
            { 
                name: "Заявки",
                route: "/requests",
            },
            { 
                name: "Договоры",
                route: "/contracts",
            },
            { 
                name: "Обработанные",
                route: "/handled",
            },
            { 
                name: "Канцелярия",
                route: "/chancellary",
            },
            { 
                name: "Корреспонденция",
                route: "/correspondence",
            },
            { 
                name: "НПА",
                route: "/npa"
            },
            { 
                name: "Архив",
                route: "/archive",
            },            
            { 
                name: "ДСП",
                route: "/dsp",
            },
            { 
                name: "Контроль",
                route: "/executioncontrol",
            },
            {
                name: process.env.VUE_APP_CONFIGURATION === sys.Configurations.C4 ? "Задачи" : "Наряды",
                route: "/tasks",
            },
            { 
                name: "Отчеты",
                route: "/reports",
            },
            { 
                name: "Совещания",
                route: "/events-management",
            },
            { 
                name: "Запросы",
                route: "/reportsgeneration",
            },
            { 
                name: "Справочники",
                route: "/settings",
            }
        ]
    },
    mutations: {
        SET_TAB (state, payload) {
            state.tab = payload;
        },
    },
    actions: {
        
    },
    getters: {
        getMyChapters: (state, getters, rootState, rootGetters) =>
        {
            let userInfo = rootGetters['auth/getUserInfo'];
            let items = state.chapters.reduce( function (items, chapter) {
                if (checkAccess(chapter, userInfo?.permissions))
                    items.push({ name: chapter.name, route: chapter.route });
                return items;
            }, []);

            if (Vue.config.devtools) {                
                items.push({ name: "Песочница", route: "/sandbox" });
            }
            
            return items;
        },
        getTab: s => s.tab,
        getFirstChapterRoute: (s, g) => g.getMyChapters?.find(c => c.route)?.route ?? "",
    }
}

export default header;